/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

header nav {
  max-width: 100%;
  margin: 0;
}

@media screen and (min-width: 851px) {
  header .button {
    left: 120px;
  }
}
header .button {
  background-color: var(--background-color);
  position: fixed;
  top: 12px;
  border-color: transparent;
  z-index: 20;
  padding: 8px 15px;
  font-size: 16px;
  opacity: 1;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
}

.button.postpone:after {
  background-image: url('/images/common/postpone.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 18px;
  height: 18px;
  margin-left: 5px;
  content: '';
  float: right;
}

@media screen and (max-width: 850px){
  header {
    /* z-index: 10; */
    /* background-color: transparent; */
    border: none;
  }

  ul#quotation-panel > li > ul {
    padding-top: 90px;
  }

  li.nav-dropdown {
    top: 70px !important;
    left: 120px !important;
    font-size: 14px;
  }

  header .button {
    right: 90px;
  }

  .nav-dropdown-content {
    left: 60px;
    width: 100px;
  }
}

@media screen and (max-width: 550px){
  header .button.postpone {
    right: 1px;
    top: 90px;
    padding: 10px;
    font-size: 0;
    min-width: auto;
  }
  .button.postpone:after {
    margin-left: 0;
  }
}

#install-button {
  background-color: rgba(255, 0, 0, 0.8);
  border-color: rgba(255, 0, 0, 0.8);
  padding: 10px 15px;
}

#install-button:hover {
  background-color: #4e4e4e;
  border-color: #4e4e4e;
}

.navigation {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin: 0;
}

.nav-item {
  font-size: 16px;
  margin-right: 1rem;
}

.nav-item h1 {
  color: #fff;
  background-color: rgba(255, 0, 0, 0.7);
  border: solid 2px rgba(255, 0, 0, 1);
  position: absolute;
  left: 10px;
  top: 10px;
  width: 100px;
  text-align: center;
  font-weight: 700;
  font-size: 21px;
  height: auto;
  padding: 5px 10px;
  margin: 0 !important;
}

.shell .container > form {
  max-width: 100%;
  padding: 0;
}

.shell ul {
  padding: 0;
}

.shell li {
  list-style-type: none;
}

.shell a {
  text-decoration: unset;
  color: initial;
}

.shell header {
  width: 100%;
  max-width: 100%;
}

.nav-item label { font-size: 16px;}

.shell .button {
  text-decoration: none;
  color: unset;
  background-color: var(--primary-color);
  border: solid var(--primary-color) 1px;
  color: #ffffff;
  padding: 1em 1em;
  min-width: 100px;
  text-align: center;
  font-size: 16px;
}

.shell .button:hover,
.shell .button:target,
.shell .button:active{
  background-color: var(--button-hover);
  color: var(--primary-color);
  opacity: 1;
}

.shell .container {
  max-width: 1300px !important;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding-top: 60px !important;
  max-width: 100%;
  padding: 0;
  justify-content: flex-start;
}

.shell ul#product-page {
  width: 100%;
}

.shell ul#product-page > li {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.shell .section-title {
  position: relative;
}

.shell .export-data {
  background-image: url("/images/export.png");
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: -3px;
  right: 2rem;
  width: 30px;
  height: 30px;
  display: block;
  content: "";
  font-size: 0;
}

.shell #products {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row-reverse;
  max-width: 800px;
  margin: 0 auto;
  background-color: var(--background-color);
  max-width: 100%;
  padding: 20px 10px !important;
}

.shell .product {
  margin: 10px;
  max-width: 230px;
  width: 100%;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}


/* .shell .product:nth-child(6):before {
  background-image: url('/images/lp-icon.png');
}

.shell .product:nth-child(7):before {
  background-image: url('/images/transition-icon.png');
} */

.shell .product:hover:before,
.shell .product:target:before,
.shell .product:active:before,
.shell .product:focus:before {
  opacity: 1;
  transition: .2s;
}

.shell .product h1 {
  margin: 0;
  font-size: 18px;
  margin: 20px 0;
  color: #fff;
  text-align: center;
}

.shell .product p {
  padding-bottom: 1rem;
}

.shell .applications {
  margin: 4rem auto;
  padding: 0;
  list-style-type: none;
  max-width: 800px;
  width: 100%;
}

.shell .applications > .row {
  text-align: left;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.shell .applications > .row {
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: .5rem;
}

.shell .applications:nth-child(2) .row {
  border-bottom: solid 2px var(--primary-color);
}

.shell .applications:nth-child(2) .row:hover {
  border-left: none;
  border-bottom: solid 2px var(--primary-color);
  opacity: 1;
}

.shell .applications > .row:hover,
.shell .applications > .row:target,
.shell .applications > .row:focus,
.shell .applications > .row:active {
  border-left: solid 2px var(--primary-color);
  border-bottom: none;
  border-radius: 0px;
}

.shell .applications h3 {
  text-align: left;
  font-size: 26px;
  font-weight: 700;
}

.shell .applications .content {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.shell .applications .content .status {
  padding-top: .5rem;
  color: var(--primary-color);
}

.shell .applications .icon {
  width: 30px;
  height: 30px;
  font-size: 0;
}
.shell .applications .icon {
  margin: auto 0;
  position: relative;
  margin-right: 1rem;
}

.shell .applications .icon:after {
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  margin-top: 5px;
  content: "";
  float: right;
}

.shell .applications .icon:hover {
  cursor: help;
}

.shell .section-title .export-data:hover {
  cursor: pointer;
}

.shell .applications .icon:hover > .tooltip,
.shell .section-title .export-data:hover > .tooltip {
  display: inline-block;
  position: absolute;
  font-size: 12px;
  width: auto;
  top: -30px;
  left: -50%;
  height: 30px;
  line-height: 20px;
  background: #333;
  z-index: 100;
  border-radius: 5px;
  border: 1px solid #000;
  padding: .2rem 1rem;
  color: #fff;
}

.shell .section-title .tooltip:hover {
  opacity: .7;
} 

.shell .applications .incomplete:after {
  background-image: url("/images/incomplete.png");
}

.shell .applications .pending:after {
  background-image: url("/images/pending.png");
}

.shell .applications .uploading:after {
  background-image: url("/images/uploading.png");
  background-size: 120%;
}

.shell .applications .uploaded:after {
  background-image: url("/images/uploaded.png");
  background-size: 120%;
}

.shell .applications .rejected:after {
  background-image: url("/images/rejected.png");
  background-size: 120%;
}

.shell .applications .complete:after {
  background-image: url("/images/complete.png");
  background-size: 120%;
}

.shell .applications a {
  width: 100%;
}
.shell .applications a:hover {
  opacity: .7;
}

.shell .applications .section-title {
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
}

.shell .warning {
  padding: 10px 0 10px 0;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  text-align: center;
  color: #efefef;
  background-color: #d53230;
  border: solid #d53230 1px;
  border-radius: 15px;
}

.shell .warning a {
  color: #dedede;
}

#main div .offline {
  position: fixed;
  top: 0;
  width: 100%;
  border-top: 4px solid rgb(172, 51, 51);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  z-index: 101;
}

#main div .online {
  position: fixed;
  top: 0;
  width: 100%;
  border-top: 4px solid rgb(143, 238, 143);
  z-index: 101;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}
/*
#main .container form > ul {
  padding: 1rem 0 !important;
} */

.tooltip {
  background-image: none;
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
}

.tooltip:hover {
  background-color: transparent;
}

.tooltip:before {
  display: none;
}

.tooltip:after {
  display: none;
}

img {
  max-width: 200px;
}


@media screen and (max-width: 850px){

  .shell .applications {
    padding: 0 1rem;
  }
}

.error-content {
  min-height: 98vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-family: 'Helvetica', 'open-sans';
}

.error-content > img {
  max-width: 250px;
  border-radius: 20px;
}

.error-content > h1 {
  margin-top: 3rem;
}

.help-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
  opacity: .6;
  color: #eaeaea;
  z-index: 100;
}

.help-button:hover {
  text-decoration: none !important;
}

#app-version {
  position: fixed;
  text-align: center;
  width: auto;
  bottom: 15px;
  z-index: 20;
  margin: 0 auto;
  left: 50%;
  right: 50%;
}

.shell #app-version{
  width: 100%;
  background-color: #fff;
  left: 0;
  bottom: 0;
  padding: 5px 0;
}

li.nav-dropdown {
  display: inline-block;
  position: absolute;
  left: 250px;

}

li.nav-dropdown:hover label{
  opacity: .8;
  transition: .2s;
}

.nav-dropdown input[type='checkbox'] {
  opacity: 1;
  appearance: none;
  -moz-appearance: none;
  -ms-progress-appearance: none;
  -webkit-appearance: none;
  border-radius: 100px;
  background-color: rgb(143, 238, 143);;
  width: 16px;
  min-width: 16px;
  height: 16px;
  cursor: pointer;
  border: solid 2px transparent;
  outline: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.nav-dropdown input[type='checkbox']:hover {
  border: solid 2px #fff;
}

.nav-dropdown input[type='checkbox']:checked {
  background-color: #fff;
}

.nav-dropdown-content {
  display: none;
  position: absolute;
  left: 0;
  top: 20px ;
  width: 100%;
  background-color: var(--dark-grey);
  min-width: 80px;
  border-radius: 5px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  /* margin-top: 0px; */
  animation-duration: .2s;
  animation: fadeInDown .2s 0s forwards;
  animation-timing-function: ease-out;
  animation-delay: 0;
  line-height: 18px;
}

.nav-dropdown label {
  background-color: transparent;
  /* color: #334835; */
  color: #fff;
  border-radius: 30px;
  padding: .1rem .3rem;
  cursor: pointer;
  z-index: 120;
}

@media screen and (max-width: 650px){
  .nav-dropdown label {
    padding-left: 0;
  }

  li.nav-dropdown {
    margin-top: .5rem;
    position: absolute;
    left: 5px;
    z-index: 300;
    margin: 0;
  }
}

.nav-dropdown-content a {
  color: #fff;
  padding: 10px 16px;
  text-decoration: none;
  display: block;
  text-align: center;
  border: solid 1px transparent;
}

.nav-dropdown-content a:hover {
  /* border-radius: 30px; */
  background-color: rgba(233, 233, 233, 0.2);
}

.nav-dropdown input[type="checkbox"]:checked ~ .nav-dropdown-content {
  display: block;
  margin-top: .5rem;
}


.product-page > li {
  padding-left: 0 !important;
}

#main .container form .product-page {
  width: 100%;
  margin-top: 0;
  padding: 0;
}

/* Because product name is not an input for ELAC online */
ul#quotation-information label[for="product"] { display: none; }
ul#quotation-information input#product.disabled {
  position: fixed;
  top: calc(60px + 30px);
  right: 150px;
  background-color: #F4F4F4;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  padding: 10px 5px;
  min-width: 250px;
  max-width: 250px;
  width: 100%;
  font-size: 16px;
  text-align: center !important;
  font-weight: 400;
}

@media screen and (max-width: 850px){
  ul#quotation-information input#product.disabled {
    top: calc(60px + 44px);
    right: 0;
    font-size: 14px !important;
    max-width: 210px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 10;
  }
}

@media screen and (max-width: 550px){
  ul#quotation-information input#product.disabled {
    display: none;
  }
}

/* This should not be needed, but styling for EL is NOT generic
   This is needed to overide specific logic on EL FFP Unilimited digital that should not be there
*/
div[class^="ffp-"] div:not(.modal-open) ul#personal-details #other-nationality {
  margin-top: 5px;
}

div[class^="ffp-"] div:not(.modal-open) ul#personal-details #other-nationality + .error-description {
  position: static;
  top: auto;
}

.has-value label[for="other-nationality"] {
  top: 11px !important;
}

div[class^="ffp-"] div:not(.modal-open) ul#personal-details #other-nationality + .error-description li {
  color: #EF523C;
}

.field input.disabled {
  border: 0 !important;
  height: auto !important;
  padding: 0 !important;
  border-radius: 0 !important;
}

li.nav-dropdown {
  top: 25px !important;
}
